import styled from 'styled-components'

const RecrutementContainer = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  max-height: 700px; // fix cropped image in iframe

  & > iframe {
    width: 100%;
  }
`

export default RecrutementContainer
